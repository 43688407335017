/*
 * @Author: 脉迈
 * @Date: 2021-11-11 14:18:07
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-11 15:12:33
 */
/* eslint-disable */ 
import React, { useState, useEffect } from 'react';
import styles from './Login.module.scss';
import { createPortal } from 'react-dom';
import b1 from '../../static/image/b_1.png';
import b2 from '../../static/image/b_2.png';
import b3 from '../../static/image/b_3.png';
import intl from 'react-intl-universal';
function Login(props:any) {
  const [node, setNode] = useState<any>('')
  const [userName,setUserName] = useState('')
  const [userPassword,setUserPassword] = useState('')
  useEffect(() => {
    let el: any = document.createElement('div')
    setNode(el)
    document.body.appendChild(el);
    return () => {
      document.body.removeChild(el);
    }
  }, [])

  const handleLogin = ()=>{
    
    props.onClose()
  }

  return node && createPortal(
    <>
      <div className={styles['login-box']}>
        <div className={styles.close} onClick={()=>{props.onClose()}}></div>
        <div className={styles.container}>
          <div className={styles.item}>
            <i className={styles['user-icon']}></i>
            <input type="text" placeholder={intl.get("1014")} onChange={(e)=>{setUserName(e.target.value)}}/>
          </div>
          <div className={styles.item}>
            <i className={styles['password-icon']}></i>
            <input type="password" placeholder={intl.get("1015")} onChange={(e)=>{setUserPassword(e.target.value)}} />
          </div>
          <div className={styles.submit} onClick={()=>{handleLogin()}}>
            {`登入`}
          </div>
          <div className={styles.other}>
            <div className={styles.title}>{intl.get("1018")}</div>
            <div className={styles.btns}>
              <a href="#" className={styles['btn-item']}>
                <img src={b1}  width="100%" alt=""/>
              </a>
              <a href="#" className={styles['btn-item']}>
                <img src={b2}  width="100%" alt=""/>
              </a>
              <a href="#" className={styles['btn-item']}>
                <img src={b3}  width="100%" alt=""/>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mark}></div>
    </>,
    node
  )
}

export default Login;