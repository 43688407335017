/*
 * @Author: 蛋蛋
 * @Date: 2021-04-12 11:05:41
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-11 11:29:31
 */
/*eslint-disable */
import React, { useState, useEffect } from 'react';
import styles from './GameDetail.module.scss';
import { createPortal } from 'react-dom';
import {getGameDetail} from '../../api/index';
export default function GameDetial(props:any) {
  const [node, setNode] = useState<any>('')
  const [data, setData] = useState({
    head_picture:'',
    game_name:'',
    game_content:'',
    url_list:{
      facebook:{
        url:''
      },
      google:{
        url:''
      },
      apple:{
        url:''
      }
    }
  })
  useEffect(() => {
    let el: any = document.createElement('div')
    setNode(el)
    document.body.appendChild(el)
    getGameDetail({game_id:props.id}).then((res:any)=>{
      if(res.code===0){
        setData(res.data)
      }
    })
    return () => {
      document.body.removeChild(el)
    }
  }, [])
  return node && createPortal(
    <>
      <div className={styles['game-box']}>
        <div className={styles.close} onClick={()=>{props.onClose()}}></div>
        <div className={styles.icon}>
          <img src={data.head_picture} alt=""/>
        </div>
        <div className={styles.wen}>
          <div className={styles.title}>{data.game_name}</div>
          <div className={styles.desc} dangerouslySetInnerHTML={{__html: data.game_content}}></div>
        </div>
        <div className={styles.btns}>
          <a href={data.url_list.facebook.url} className={`${styles.fb} ${styles.btn}`}></a>
          <a href={data.url_list.google.url} className={`${styles.gp} ${styles.btn}`}></a>
          <a href={data.url_list.apple.url} className={`${styles.as} ${styles.btn}`}></a>
        </div>
      </div>
      <div className={styles.mark}></div>
    </>,
    node
  )
}
