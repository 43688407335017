/*
 * @Author: 蛋蛋
 * @Date: 2021-04-12 11:54:21
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-11 15:13:13
 */
import React,{useState,useEffect} from 'react'
import {createPortal} from 'react-dom';
import styles from './Kefu.module.scss';
import {getConcatData} from '../../api/index';
import intl from 'react-intl-universal';
export default function Concat(props:any) {
  const [node,setNode] = useState<any>('')
  const [concatData,setConcatData] = useState({attendant_desc:'',attendant_time:'',attendant_mail:''})
  useEffect(() => {
    let el:any = document.createElement('div')
    setNode(el)
    document.body.appendChild(el);
    loadConcatData()
    return ()=>{
      document.body.removeChild(el);
    }
  }, [])
  const loadConcatData = async()=>{
    const res:any = await getConcatData()
    let {data} = res
    setConcatData(data)

  }
  return node && createPortal(
    <>
      <div className={styles['service-box']}>
        <div className={styles.box}>
          <div className={styles['title']}>{concatData.attendant_desc}</div>
          <div className={styles['desc']}>{intl.get("1012")}{concatData.attendant_time}</div>
          <div className={styles['desc']}>Email：{concatData.attendant_mail}</div>
        <div className={styles['btn']} onClick={()=>{props.onClose()}}>{intl.get("1013")}</div>
        </div>
      </div>
      <div className={styles.mark}></div>
    </>,
    node
  )
}