/*
 * @Author: 脉迈
 * @Date: 2021-11-10 14:41:14
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-11 15:21:22
 */
import React from 'react';
import styles from './Footer.module.scss';
import intl from 'react-intl-universal';
import {NavLink} from 'react-router-dom';
const Footer =()=> {
  return (
    <div>
       <div className={styles['footer']}>
         <div className={styles['footer-container']}>
           <span>NEWAGAMESCO.,LTD.</span>
           |
           <span><NavLink to="/yhxy">{intl.get('1008')}</NavLink></span>
           |
           <span><NavLink to="/yszc">{intl.get('1009')}</NavLink></span>
         </div>
       </div>
    </div>
  )
}

export default Footer;
