/*
 * @Author: 脉迈
 * @Date: 2021-11-10 17:16:23
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-11 15:34:54
 */
import React from 'react';
import {Route,Switch} from 'react-router-dom';
import Index from './pages/index/index';
import About from './pages/about/index';
import Recharge from './pages/recharge/index';
import Yhxy from './pages/yhxy/index';
import Yszc from './pages/yszc/index';

function App() {
  return (
    <div>
      <Switch>
        <Route path="/" component={Index} exact/>
        <Route path="/about" component={About} exact/>
        <Route path="/recharge" component={Recharge} exact/>
        <Route path="/yhxy" component={Yhxy} exact/>
        <Route path="/yszc" component={Yszc} exact/>
      </Switch>
    </div>
  );
}

export default App;
