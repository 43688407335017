/*
 * @Author: 脉迈
 * @Date: 2021-11-10 11:55:20
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-11 11:19:26
 */
import {getAxios} from '../utils/axios';
export const getGameList = ()=>{
  return getAxios({url:'/game/get_first_page_game_list'})
}

export const getGameDetail = (params:any) =>{
  return getAxios({
    url:'/game/get_game_detail',
    params
  })
}

export const getAboutUs = ()=>{
  return getAxios({
    url: '/show_info/about_us'
  })
}

export const getConcatData = ()=>{
  return getAxios({
    url: '/show_info/attendant_info'
  })
}