/*
 * @Author: 脉迈
 * @Date: 2021-11-11 15:16:00
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-12 15:17:28
 */

import React, {useEffect} from 'react';
import TopBar from '../../components/TopBar/TopBar';
import hans from './lang/hans';
import hant from './lang/hant';
import {getLocal} from '../../utils/index';
import styles from './index.module.scss';
function Index() {
  useEffect(() => {
    
  }, [])
  return (
    <div>
      <TopBar></TopBar>
      {/* 简体 */}
      {
        getLocal('lang') === 'hans' ?
        <div className={styles.container} dangerouslySetInnerHTML={{__html: hans}}>
        
        </div>
      :
        <></>
        
      }
      {/* 繁体 */}
      {
        getLocal('lang') === 'hant' ?
        <div className={styles.container} dangerouslySetInnerHTML={{__html: hant}}>
       
        </div>
        :
        <div className={styles.container} dangerouslySetInnerHTML={{__html: hant}}>
        
        </div>
      }
      
     
    </div>
  )
}

export default Index;
