/*
 * @Author: 蛋蛋
 * @Date: 2021-04-07 20:11:35
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-12 17:40:25
 */
/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import styles from './TopBar.module.scss';
import {NavLink} from 'react-router-dom';
import Logo from '../../static/image/logo.png';
import Kefu from '../../components/Kefu/Kefu';
import intl from 'react-intl-universal';
import { getLocal, saveLocal } from '../../utils/index';
import { Popup, Radio, Space } from 'antd-mobile';
const locales = {
  "hant": require('../../locales/zh-Hant.json'), // 繁体
  "hans": require('../../locales/zh-Hans.json'), // 简体
};
function TopBar() {
  const [showService, setShowService] = useState(false);
  const [selectLanguage, setSelectLanguage] = useState(false);
  const [showLogin,setShowLogin] = useState(false);
  const [initDone, setInitDone] = useState(false);
  const [language, setLanguage] = useState('hans')
  const [visible, setVisible] = useState(false);
// 初始化语言
const loadLocales = () =>{
  let lang = getLocal('lang') // 读取当前的语言设置
  
  switch (lang) {
    case 'hans':
      intl.init({
        currentLocale: 'hans', // TODO: determine locale here
        locales
      }).then(()=>{
        setInitDone(true)
        saveLocal('lang','hans')
      })
      break;
    case 'hant':
      intl.init({
        currentLocale: 'hant', // TODO: determine locale here
        locales
      }).then(()=>{
        setInitDone(true)
        saveLocal('lang','hant')
      })
      break;
    case 'en':
      intl.init({
        currentLocale: 'en', // TODO: determine locale here
        locales
      }).then(()=>{
        setInitDone(true)
        saveLocal('lang','en')
      })
      break;
  
    default:
        intl.init({
          currentLocale: 'hant', // TODO: determine locale here
          locales
        }).then(()=>{
          setInitDone(true)
          saveLocal('lang','hant')
        })
      break;
  }

}
const setLang = (language:string) => {
  saveLocal('lang',language)
  window.location.reload();
}
useEffect(()=>{
  loadLocales()
})
  return (
    <>
      <div className={styles.header}>
        <div className={styles['top-bar']}>
          <div className={styles['logo']}>
            <img src={Logo} width="100%" alt=""/>
          </div>
          <div className={styles['nav']}>
              
            <ul>
                <li>
                <NavLink to="/" activeClassName={styles.active} exact>
                {intl.get("1001")}
              </NavLink>
                </li>
                <li>
              <NavLink to="/about" activeClassName={styles.active} exact>
                {intl.get("1002")}
              </NavLink>
              </li>
              <li>
              <NavLink to="/recharge" activeClassName={styles.active} exact>
                {intl.get("1003")}
              </NavLink>
              </li>
              <li onClick={() => { setShowService(true) }}>
                <a>{intl.get("1004")}</a>
              </li>
            </ul>
          </div>
          <span className={styles.language} onClick={()=>{setVisible(true)}}>Language</span>
        </div>
      </div>
      {showService ? <Kefu onClose={() => { setShowService(false) }}></Kefu> : <></>}
      <Popup
              visible={visible}
              onMaskClick={() => {
                setVisible(false)
              }}
              bodyStyle={{ minHeight: '20vh' }}
            >
              <Radio.Group
                value={language}
                onChange={(val: any) => {
                  setLang(val)
                }}
              >
            <Space direction='vertical' 
              style={{padding:'10px'}}>
              {/* <Radio value='hans'>简体</Radio> */}
              <Radio value='hant'>繁体</Radio>
              {/* <Radio value='en'>English</Radio> */}
            </Space>
        </Radio.Group>
            </Popup>
    </>
  )
}

export default TopBar