/*
 * @Author: 蛋蛋
 * @Date: 2021-04-12 14:49:06
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-11 14:37:00
 */
/* eslint-disable */ 
import React, { useState } from 'react';
import TopBar from '../../components/TopBar/TopBar';
import styles from './recharge.module.scss';
import Login from '../../components/Login/Login';
import Footer from '../../components/Footer/Footer';
import { PickerView } from 'antd-mobile';
import Pircker from '../../components/Picker/Picker';
import bg from '../../static/image/bg.jpg';
const seasons = [
  [
    {
      label: '2013',
      value: '2013',
    },
    {
      label: '2014',
      value: '2014',
    },
  ]
];
function Recharge(props: any) {
  const [showLogin, setShowLogin] = useState(false)
  const [selectServer, setSelectServer] = useState(false)
  const [serverVal, setServerVal] = useState([])

  // 登出
  const logout = ()=>{

    window.location.reload()
  }
  // 選擇服務器
  const onChangeServer = ()=>{
    console.log('111')
  }

  return (
    <>
      <div style={{minHeight:'95vh'}}>
        <TopBar></TopBar>
        <div className={styles.banner}>
          <img src={bg} alt="" />
          <div className={styles.box}>
            <p className={styles.title}>儲值中心</p>
            <p className={styles.tip}>TOP UP</p>
          </div>
        </div>
        <div className={styles['select-box']}>
          <div className={styles['user']}>
            <i className={styles['icon']}></i>
            {/* {userStore.userName ? <>
              <span className={styles['user-name']}>{userStore.userName}</span>
              <span className={styles['logout']} onClick={() => { logout() }}>[登出]</span>
            </> : } */}
            <span className={styles.login} onClick={()=>{setShowLogin(true)}}>[登入]</span>
          </div>
          <div className={styles.select} onClick={()=>{setSelectServer(true)}}>
            <span>選擇伺服器</span>
            <i></i>
          </div>
          <div className={`${styles.select}`}>
            <span>選擇遊戲</span>
            <i></i>
          </div>
          <div className={`${styles.select}`}>
            <span>選擇角色</span>
            <i></i>
          </div>
        </div>
      </div>
      <Footer></Footer>
      {showLogin ? <Login onClose={() => { setShowLogin(false) }}></Login> : <></>}
      {selectServer ?<div className={styles.picker}>
        <Pircker
        onCancel={()=>{setSelectServer(false)}}
        onOk={()=>{setSelectServer(false)}}
        >
          <PickerView
            onChange={onChangeServer}
            value={serverVal}
            columns={seasons}
          />
        </Pircker>
      </div>:<></>}
    </>
  )
}
export default Recharge