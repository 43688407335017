/*
 * @Author: 脉迈
 * @Date: 2021-11-11 14:14:10
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-11 14:31:36
 */
import React from 'react';
import styles from './Picker.module.scss';
export default function Picker(props:any) {
  return (
    <div>
      <div className={styles.bar}>
        <span onClick={props.onCancel}>取消</span>
        <span onClick={props.onOk}>確定</span>
      </div>
      {props.children}
    </div>
  )
}
