/*
 * @Author: 脉迈
 * @Date: 2021-11-11 10:41:42
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-11 11:49:11
 */
import React, {useState, useEffect} from 'react'
import TopBar from '../../components/TopBar/TopBar';
import Footer from '../../components/Footer/Footer';
import GameDetail from '../../components/GameDetail/GameDetail';
import styles from './index.module.scss';
import Swiper from 'swiper';
import 'swiper/swiper.min.css';
import banner1 from '../../static/image/banner1.png'
import banner2 from '../../static/image/banner2.png'
import { getGameList } from '../../api/index';
import intl from 'react-intl-universal';
function Index() {
  const [data, setData] = useState<any>([])
  const [detailId, setDetailId] = useState(0)
  useEffect(() => {
    
    getGameList().then((res:any)=>{
      if(res.code===0){
        setData(res.data)
        setTimeout(()=>{
          new Swiper('.swiper-container', {
            slidesPerView: 2.5,
            spaceBetween: 30
          })
        },500)
      }
    })
  }, [])
 
  return (
    <div>
      <TopBar></TopBar>
      <div className={styles.banner1}>
        <img src={banner1} alt="" />
        <p>{intl.get('1005')}</p>
      </div>
      <div className={styles.banner2}>
        <img src={banner2} alt="" />
        <p>{intl.get('1006')}</p>
      </div>
      <div className={styles['games']}>

        <div className={styles['games-con']}></div>
        <div className={styles['games-c']}>
          <div className={`${styles['games-container']} swiper-container`}>
            <div className={`swiper-wrapper ${styles['games-container']}`}>
              {
                data.map((item:any, index:number) => {
                  return (

                    <div className={`${styles['item']} swiper-slide`} key={index}>
                      <div className={styles['first']} style={{ 'backgroundImage': `url(${item.big_picture})` }}>
                        <div className={styles['inner']}></div>
                      </div>
                      <div className={styles['item-active']} onClick={()=>{setDetailId(item.id)}}>
                        <img src={item.big_picture} className={styles['send']} alt=""/>
                        <div className={styles.box}>
                          <div className={styles['game-desc']}>
                            <p className={styles['game-name']}>{item.game_name}</p>
                            <p className={styles['game-des']}>{item.game_desc}</p>
                          </div>
                          <div className={styles['game-icon']}>
                            <img src={item.head_picture} alt=""/>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            {detailId!==0 ? <GameDetail onClose={()=>{setDetailId(0)}} id={detailId}></GameDetail>:<></>}

          </div>
          {/* <div className={`${styles['swiper-button-prev']} ${styles['swiper-button']}`} onClick={() => { prev() }}></div>
          <div className={`${styles['swiper-button-next']} ${styles['swiper-button']}`} onClick={() => { next() }}></div> */}
        </div>

      </div>
      <Footer></Footer>
    </div>
  )
}

export default Index;