/*
 * @Author: 脉迈
 * @Date: 2021-11-11 11:52:08
 * @LastEditors: 脉迈
 * @LastEditTime: 2021-11-11 11:58:13
 */
import React, { useEffect, useState } from 'react';
import TopBar from '../../components/TopBar/TopBar';
import Footer from '../../components/Footer/Footer';
import styles from './index.module.scss';
import {getAboutUs} from '../../api/index';
import about1 from '../../static/image/about-1.png';
import about2 from '../../static/image/about-2.png';

function Index(props:any) {
  const [data, setData] = useState([])
  useEffect(() => {
    getAboutUs().then((res:any)=>{
      if(res.code===0){
        setData(res.data)
      }
    })
  }, [])
  return(
    <>
      <TopBar></TopBar>
      <img className={styles['about-banner']} src={about1} width="100%" alt="" />
      <div className={styles.about}>
        <img src={about2} alt="" className={styles['about-logo']}/>
        {data}
      </div>
      <Footer></Footer>
    </>
  )
}

export default Index